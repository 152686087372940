<template>
  <div class="inner">
    <div class="loading-box" v-show="isLoading">
      <van-loading size="24px" color="#4994df" text-color="#4994df">{{
        $t('tip.loading')
      }}</van-loading>
    </div>
    <p class="is-title">
      {{ detailMsg.title }}
    </p>
    <div class="msg-box">
      <div class="is-base" @click="showDown(0)">
        <span>{{ $t('index.basic_proposal_information') }}</span>
        <div
          class="icon"
          :style="showArr[0] ? 'transform: rotate(317deg);margin-top:2px;' : ''"
        ></div>
      </div>
      <div
        class="one-msg"
        :style="showArr[0] ? 'height:auto;' : 'height:0px;display:none;'"
      >
        <p class="title-one">{{ $t('index.background') }}</p>
        <div class="inner-one">
          {{ detailMsg.background }}
        </div>
        <div class="one-line"></div>
        <p class="title-one" style="margin-bottom:0px;">
          {{ $t('index.criteria_for_proposal_approval') }}
        </p>
        <div class="inner-one" v-if="detailMsg.module == 'COMMITTEEVOTE'">
          <span v-if="detailMsg.participate == '0'"
            >1.
            {{ $t('second.Positive_votes_exceed_of_the_total_votes') }}</span
          ><br />
          <span v-if="detailMsg.participate != '0'"
            ><span
              >1.{{ $t('second.More_than') }}{{ detailMsg.participate }}%{{
                $t('second.of_the_governance')
              }}</span
            >
            <span><br /></span>
            2.{{ $t('second.Positive_votes_exceed_of_the_total_votes') }}</span
          >
        </div>
        <div class="one-line"></div>
        <p class="title-one" v-if="detailMsg.annex_name">
          {{ $t('index.proposal_attachment') }}
        </p>
        <div class="inner-one" v-if="detailMsg.annex_name">
          <a :href="detailMsg.pdf_url" v-if="this.mobileKind == 'ios'">{{
            detailMsg.annex_name
          }}</a>
          <div @click="jumpPdf" v-else style="color:#22a6f1">
            {{ detailMsg.annex_name }}
          </div>
        </div>
        <div class="one-line" v-if="detailMsg.annex_name"></div>

        <p class="title-one">{{ $t('index.initiator') }}</p>
        <div class="inner-one">{{ detailMsg.sponsor }}</div>
        <div class="one-line"></div>
        <p
          class="title-one"
          v-if="
            detailMsg &&
              detailMsg.seconded_list &&
              detailMsg.seconded_list.length > 0
          "
        >
          {{ $t('second.Supported_By') }}
        </p>
        <div class="inner-one">
          <span
            style="margin-right:6px;"
            v-for="(item, index) in detailMsg.seconded_list"
            :key="index"
            >{{ item.name }}</span
          >
        </div>
      </div>
    </div>

    <div class="msg-box">
      <div class="is-base" @click="showDown(1)">
        <span>{{ $t('index.details_of_the_proposal') }}</span>
        <div
          class="icon"
          :style="showArr[1] ? 'transform: rotate(317deg);margin-top:2px;' : ''"
        ></div>
      </div>
      <div
        class="one-msg change-pic"
        :style="showArr[1] ? 'height:auto;' : 'height:0px;display:none;'"
      >
        <div
          class="is-imgone"
          :style="showArr[1] ? '' : 'display:none; '"
          v-html="detailMsg.detail"
        ></div>
      </div>
    </div>

    <div class="msg-box">
      <div class="is-base" @click="showDown(2)">
        <span>{{ $t('index.voting_information') }}</span>
        <div
          class="icon"
          :style="showArr[2] ? 'transform: rotate(317deg);margin-top:2px;' : ''"
        ></div>
      </div>
      <div
        class="one-msg-third"
        :style="showArr[2] ? 'height:auto;' : 'height:0px;display:none;'"
      >
        <div class="third-msg">
          <span class="is-left">{{ $t('index.status') }}</span>
          <span
            class="is-right"
            style="color: #DC8722"
            v-if="detailMsg.status == 2"
            >{{ $t('second.Supporting') }}...</span
          >
          <span
            class="is-right"
            style="color: #e52323"
            v-if="detailMsg.status == -1"
            >{{ $t('index.proposal_not_approved') }}</span
          >
          <span
            class="is-right"
            style="color: #4fae42"
            v-if="detailMsg.status == 1"
            >{{ $t('index.proposal_approved') }}</span
          >
          <span
            class="is-right"
            style="color: #dc8722"
            v-if="detailMsg.status == 0"
            >{{
              voteKind(
                new Date().getTime(),
                detailMsg.start_time,
                detailMsg.end_time
              )
            }}</span
          >
        </div>
        <div class="third-msg">
          <span class="is-left">{{ $t('index.start_time') }}</span
          ><span
            class="is-right"
            v-if="detailMsg && Object.keys(detailMsg).length > 0"
          >
            {{ timeKind(detailMsg.start_time, 1) }} &nbsp;
            {{ timeKind(detailMsg.start_time, 2) }}</span
          >
        </div>

        <div class="third-msg">
          <span class="is-left">{{ $t('index.end_time') }}</span
          ><span class="is-right" v-if="Object.keys(detailMsg).length > 0">
            {{ timeKind(detailMsg.end_time, 1) }} &nbsp;
            {{ timeKind(detailMsg.end_time, 2) }}</span
          >
        </div>
        <div class="third-msg">
          <span class="is-left">{{ $t('index.type') }}</span
          ><span class="is-right" v-if="detailMsg.type == 1">{{
            $t('index.token_holders_vote')
          }}</span>
          <span class="is-right" v-if="detailMsg.type == 2">{{
            $t('second.Governance_Committee_Members_Vote')
          }}</span>
        </div>
        <div class="third-msg">
          <span class="is-left">{{
            $t('index.total_number_of_votes_available')
          }}</span
          ><span class="is-right">{{ detailMsg.total_votes }}</span>
        </div>
        <!-- 
        <div class="third-msg" @click="toNojoin">
          <span class="is-left">{{
            $t('index.non_participating_address')
          }}</span
          ><span class="is-right" style="color: #009eee"
            >{{ $t('index.view') }}></span
          >
        </div> -->
      </div>
    </div>
    <div
      class="btn-cx"
      @click="SignDataEvent('chexiao')"
      v-if="
        detailMsg.sponsor_account == $store.state.account.name &&
          detailMsg.status == 2 &&
          showInitiateBtn
      "
    >
      {{ $t('second.Cancel') }}
    </div>
    <div
      class="btn-fy"
      @click="SignDataEvent('fuyi')"
      v-if="
        detailMsg.status != -1 &&
          detailMsg.status != 1 &&
          secondMsg.data == null &&
          detailMsg.sponsor_account != $store.state.account.name &&
          showInitiateBtn
      "
    >
      {{ $t('second.Support_The_Proposal') }}
    </div>
    <div
      class="btn-yfy"
      v-if="
        secondMsg.data != null &&
          detailMsg.sponsor_account != $store.state.account.name &&
          showInitiateBtn
      "
    >
      {{ $t('second.Proposal_Supported') }}
    </div>
    <div style="height:10px;" v-if="voteStatus == '0'"></div>
    <div v-if="voteStatus != '0'">
      <div class="msg-box">
        <div class="is-base">
          <span>{{ $t('index.vote') }}</span>
        </div>
        <!-- 投票的3个柱子 -->
        <div
          class="msg-par"
          v-for="(item, index) in voteMsg.voteArr"
          :key="index"
        >
          <div class="under-line">
            <div
              class="color-line"
              :style="
                item.info == $t('index.agree')
                  ? 'backgroundColor:#5ECE54;width:' + agree * 100 + '%'
                  : item.info == $t('index.disagree')
                  ? 'backgroundColor:#DD6C6C;width:' + oppose * 100 + '%'
                  : 'backgroundColor:#B3BCC7;width:' + giveUp * 100 + '%'
              "
            ></div>
          </div>
          <div class="points-msg">
            <div :style="item.isCheck ? 'color:#181A33' : 'color:#777789'">
              <span v-show="item.isCheck">{{ $t('index.voted') }}&nbsp;</span
              >{{
                item.info == $t('index.agree')
                  ? $t('index.yes_vote')
                  : item.info == $t('index.disagree')
                  ? $t('index.no_vote')
                  : $t('index.abstention_vote')
              }}
              <img v-show="item.isCheck" src="../assets/yes.png" alt="" />
            </div>
            <span>{{ item.number }}</span>
          </div>
        </div>

        <!-- 用户没有投过的时候 -->
        <div v-if="!userVote && voteStatus == 1 && showInitiateBtn">
          <div
            class="points"
            style="background-color: #5ece54"
            @click="SignDataEvent('toupiao', 1)"
          >
            {{ $t('index.agree') }}
          </div>
          <div
            class="points"
            style="background-color: #dd6c6c"
            @click="SignDataEvent('toupiao', -1)"
          >
            {{ $t('index.disagree') }}
          </div>
          <div
            class="points"
            style="background-color: #b3bcc7"
            @click="SignDataEvent('toupiao', 0)"
          >
            {{ $t('index.abstain') }}
          </div>
          <div class="last-box"></div>
        </div>
        <!-- 改投 -->
        <div class="vote-info">
          <div class="is-change" v-if="voteStatus == '1' && userVote">
            <div
              class="change-box"
              v-for="(item, index) in voteMsg.voteArr"
              :key="index"
              @click="toPoints(item.id)"
              :style="!item.isCheck ? 'width: 47.742%;' : 'width: 0;'"
            >
              <div
                v-show="!item.isCheck"
                class="change"
                :style="
                  item.info == $t('index.agree')
                    ? 'backgroundColor:#5ECE54;'
                    : item.info == $t('index.disagree')
                    ? 'backgroundColor:#DD6C6C;'
                    : 'backgroundColor:#B3BCC7;'
                "
              >
                <span>{{ $t('index.change_the_vote') }}{{ item.info }}</span>
              </div>
            </div>
          </div>

          <div class="vote-under">
            <div class="already" v-if="userVote">
              <span class="one"
                >{{ $t('index.you_have_cast_vote')
                }}{{ voteMsg.voteArr[voteNum].info
                }}{{ $t('index.voteInfo') }}</span
              >
              <span class="two" v-if="voteRes.user">{{
                voteRes.user.vote_number
              }}</span>
            </div>
            <div class="already" v-if="userVote">
              <span class="one">{{ $t('index.last_updated_time') }}</span>
              <span class="two" v-if="voteRes.user"
                >{{ timeKind(voteRes.user.last_time, 1) }} &nbsp;{{
                  timeKind(voteRes.user.last_time, 2)
                }}</span
              >
            </div>
            <div class="vote-detail" @click="jumpVoteDetail">
              {{ $t('index.voting_detail') }}>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//  ,userVote

// 判断逻辑  情况
// 投票未开始
// 不展示投票的信息
// 投票进行中了
// 用户投票了
// 展示用户投票的信息 , 用户可以选择切换的投票的按钮1
// 用户没有投票
// 光展示 投票的结果信息 ,用户可以点击全部的投票的按钮1
// 投票已结束
// 用户投票了
// 展示投票结果  ,展示用户投票的信息 ,且用户不能选择投票的按钮1
// 用户没有投票
//只展示投票的结果
import {
  getProposalData,
  voteResult,
  SecondedResults,
  CommitteeSecondedVote,
  revokeProposal,
  committeeVote,
} from '@/api/detail'
import { getSignData } from '@/api/index'
import { timeType } from '@/utils/index'
import { Toast } from 'vant'
export default {
  inject: ['reload'],
  data() {
    return {
      showArr: [true, true, true, true],
      isPoints: false,
      voteMsg: {
        voteArr: [
          { number: 4510, isCheck: false, info: '赞同', id: 0 },
          { number: 2395, isCheck: false, info: '反对', id: 1 },
          { number: 6714, isCheck: false, info: '弃权', id: 2 },
        ],
        lastTime: '2021-03-24 21:34:12',
        total: 11000,
      },
      voteNum: 0,
      detailMsg: {},
      voteStatus: 0, // 投票的状态    0 :未开始  1 :进行中   2 :已结束
      userVote: true, //用户的状态   true 是 已经投过   false :未投过
      voteRes: {},
      isLoading: false,
      secondMsg: {},
    }
  },
  methods: {
    // 获取签名
    async SignDataEvent(str, info) {
      let _this = this
      this.isLoading = true
      let res = await getSignData()
      if (res.code == 0) {
        let obj = {}
        obj.signature =
          res.data.timestamp.toString() +
          res.data.id.toString() +
          res.data.random.toString()
        obj.account = this.$store.state.account.name
        obj.random = res.data.random.toString()
        if (str == 'chexiao') {
          obj.id = this.$route.query.id
          let resMsg = await revokeProposal(obj)
          // console.log(resMsg)
          if (resMsg.code == 0) {
            Toast.success(this.$t('second.Cancelled'))
            setTimeout(() => {
              _this.$router.push({
                path: '/',
              })
            }, 2000)
          }
        } else if (str == 'fuyi') {
          obj.id = this.$route.query.id
          let resMsg = await CommitteeSecondedVote(obj)
          // console.log(resMsg)
          if (resMsg.code == 0) {
            this.isLoading = false
            Toast.success(this.$t('second.Supported_Successfully'))
            _this.getDetail(this.$route.query.id)
            _this.getVoteResult()
            _this.getSecondResult()
          }
        } else if (str == 'toupiao') {
          obj.id = this.$route.query.id
          obj.vote_type = info
          let resMsg = await committeeVote(obj)
          if (resMsg.code == 0) {
            Toast.success(this.$t('enroll.vote_successfully'))
            await this.getVoteResult()
            for (let i = 0; i < this.voteMsg.voteArr.length; i++) {
              this.voteMsg.voteArr[i].isCheck = false
            }
            this.voteMsg.voteArr[this.voteNum].isCheck = !this.voteMsg.voteArr[
              this.voteNum
            ].isCheck
            this.userVote = true
            this.isLoading = false
          }
        }
      }
      this.isLoading = false
    },
    jumpPdf() {
      this.$router.push({
        path: '/pdf',
        query: { pdfUrl: this.detailMsg.pdf_url },
      })
    },
    // 获取投票结果信息 , 判断用户是否投过票了
    async getVoteResult() {
      this.isLoading = false
      let obj = {
        id: this.$route.query.id,
        account: this.$store.state.account.name,
      }
      let res = await voteResult(obj)
      this.voteRes = res.data
      // 判断用户是否投票了
      this.voteMsg.voteArr[0].number = res.data.agree_num
      this.voteMsg.voteArr[1].number = res.data.oppose_num
      this.voteMsg.voteArr[2].number = res.data.waiver_num
      if (res.data && res.data.user) {
        this.userVote = true
        // 判断用户投了什么
        if (this.voteRes.user.vote_type == '1') {
          this.voteMsg.voteArr[0].isCheck = true
          this.voteNum = 0
        } else if (this.voteRes.user.vote_type == '-1') {
          this.voteMsg.voteArr[1].isCheck = true
          this.voteNum = 1
        } else if (this.voteRes.user.vote_type == '0') {
          this.voteMsg.voteArr[2].isCheck = true
          this.voteNum = 2
        }
      } else {
        this.userVote = false
      }
      this.isLoading = false
      console.log(this.voteMsg)
    },
    // 跳转到投票详情
    jumpVoteDetail() {
      this.$router.push({
        path: 'voteDetailSecond',
        query: { id: this.$route.query.id },
      })
    },
    // 跳转到不可参与地址的页面
    toNojoin() {
      this.$router.push({
        path: 'nojoin',
        query: { id: this.$route.query.id },
      })
    },
    //   改变投票
    changeVote() {
      // if (!this.$store.state.account.name) {
      //   Toast(this.$t('index.vote_wallet'))
      //   return
      // }
      // if (item.id == '1') {
      //   this.SignDataEvent('toupiao', -1)
      // } else if (item.id == '2') {
      //   this.SignDataEvent('toupiao', 0)
      // } else {
      //   this.SignDataEvent('toupiao', 1)
      // }
    },
    // 下拉数据
    showDown(num) {
      this.showArr[num] = !this.showArr[num]
      this.$forceUpdate()
    },
    // 用户投票
    async toPoints(num) {
      if (!this.$store.state.account.name) {
        Toast(this.$t('index.vote_wallet'))
        return
      }
      this.isLoading = true
      this.voteNum = num

      if (num == '1') {
        this.SignDataEvent('toupiao', -1)
      } else if (num == '2') {
        this.SignDataEvent('toupiao', 0)
      } else {
        this.SignDataEvent('toupiao', 1)
      }
    },

    // 获取提案数据
    async getDetail(ids) {
      let msg = await getProposalData({
        id: ids,
        language: this.$store.state.lang,
      })
      this.detailMsg = msg.data
      if (msg.data.status == 1 || msg.data.status == -1) {
        this.voteStatus = 2
      }
    },
    // 时间戳变形
    timeKind(time, num) {
      return timeType(time, num)
    },

    // 判断投票未开始和投票进行中的状态
    voteKind(time, start, end) {
      time = time.toString().substr(0, 10)
      time = time.substr(0, 10)
      //
      if (Number(start) > Number(time) && this.detailMsg.status == 0) {
        this.voteStatus = 0
        return this.$t('index.voting_did_not_start')
      } else if (
        Number(start) < Number(time) &&
        Number(time) < Number(end) &&
        this.detailMsg.status == 0
      ) {
        this.voteStatus = 1
        return this.$t('index.voting')
      }
    },
    async getSecondResult() {
      let res = await SecondedResults({
        id: this.$route.query.id,
        account: this.$store.state.account.name,
      })
      if (res.code == 0) {
        this.secondMsg = res
      }
    },
  },
  computed: {
    showInitiateBtn() {
      return (
        this.$store.state.account.name &&
        this.user &&
        this.user['is_committee'] == 1
      )
    },
    agree() {
      if (this.detailMsg.total_votes == 0) {
        return 0
      }
      return this.voteMsg.voteArr[0].number / Number(this.detailMsg.total_votes)
    },
    oppose() {
      if (this.detailMsg.total_votes == 0) {
        return 0
      }
      return this.voteMsg.voteArr[1].number / Number(this.detailMsg.total_votes)
    },
    giveUp() {
      if (this.detailMsg.total_votes == 0) {
        return 0
      }
      return this.voteMsg.voteArr[2].number / Number(this.detailMsg.total_votes)
    },
    mobileKind() {
      let agent = navigator.userAgent
      if (agent.match(/iPhone|mac|iPod|iPad|ios/i)) {
        return 'ios'
      } else {
        return 'other'
      }
    },
  },
  created() {
    this.getDetail(this.$route.query.id)
    this.getVoteResult()
    this.getSecondResult()
    this.voteMsg.voteArr[0].info = this.$t('index.agree')
    this.voteMsg.voteArr[1].info = this.$t('index.disagree')
    this.voteMsg.voteArr[2].info = this.$t('index.abstain')
  },
}
</script>
<style lang="less">
.van-loading {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  z-index: 3;
  .van-loading__text {
    color: #4994df;
  }
}
.change-pic {
  img {
    max-width: 100% !important;
  }
}
</style>
<style lang="less" scoped>
.is-title {
  width: 91.2%;
  margin: 0 auto;
  font-size: 18px;
  font-weight: bold;
  color: #181a33;
  line-height: 22px;
}
.inner {
  padding-top: 19px;
  .msg-box {
    width: 93.3%;
    background-color: #ffffff;
    margin: 14px auto 0 auto;
    border-radius: 6px;
    .one-msg {
      width: 88.57%;
      margin: 0 auto;
      font-size: 12px;
      color: #777789;
      line-height: 18px;
      padding-bottom: 31px;
      .title-one {
        font-size: 14px;
        font-weight: bold;
        color: #181a33;
        line-height: 21px;
      }
      .one-line {
        height: 1px;
        background-color: #f0f0f0;
        margin-top: 17px;
      }
    }
    // 投票相关信息
    .one-msg-third {
      width: 88.57%;
      margin: 0 auto;
      font-size: 12px;
      color: #777789;
      line-height: 18px;
      padding-bottom: 20px;
      .third-msg {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
        .is-left {
          color: #777789;
          font-size: 12px;
          line-height: 26px;
        }
        .is-right {
          font-size: 12px;
          font-weight: 400;
          color: #181a33;
          line-height: 26px;
        }
      }
    }
    // 投票
    .points {
      width: 88.57%;
      margin: 0 auto;
      text-align: center;
      height: 45px;
      line-height: 45px;
      box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.01);
      border-radius: 6px;
      font-size: 14px;
      color: #ffffff;
      margin-bottom: 9px;
    }
    .last-box {
      height: 20px;
    }

    // 已经投票的界面
    .vote-info {
      width: 88.57%;
      margin: 0 auto;
      .is-change {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding-bottom: 22px;
        border-bottom: 1px solid #f0f0f0;
        // .change-box{
        // }
        .change {
          height: 45px;
          // line-height: 45px;
          text-align: center;
          color: #ffffff;
          font-size: 14px;
          border-radius: 6px;
          display: flex;
          align-items: center;
          span {
            margin: 0 auto;
          }
        }
      }
      .vote-under {
        padding-top: 30px;
        padding-bottom: 20px;
        .already {
          justify-content: space-between;
          display: flex;
          font-size: 12px;
          margin-bottom: 18px;
          .one {
            color: #777789;
          }
          .two {
            color: #181a33;
          }
        }
        .vote-detail {
          color: #009eee;
          font-size: 12px;
        }
      }
    }
  }
  .is-base {
    width: 92%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    height: 55px;
    border-radius: 6px;
    span {
      margin-left: 5.3%;
      font-size: 14px;
      font-weight: bold;
      color: #181a33;
      line-height: 21px;
      white-space: nowrap;
    }
    .icon {
      border-right: 2.4px solid #181a33;
      border-top: 2.4px solid #181a33;
      height: 10px;
      width: 10px;
      transform: rotate(133deg);
      border-left: 2px solid transparent;
      border-bottom: 2px solid transparent;
      box-sizing: border-box;
      // margin-left: 59%;
      margin-top: -10px;
    }
  }
}
.msg-par {
  width: 88.57%;
  margin: 0 auto;
  .under-line {
    width: 100%;
    height: 16px;
    overflow: hidden;
    background-color: #e6e6e6;
    .color-line {
      height: 16px;
    }
  }
  .points-msg {
    color: #181a33;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    font-weight: bold;
    padding-bottom: 20px;
    img {
      width: 14px;
      height: 14px;
      vertical-align: -1px;
      margin-left: 3px;
    }
  }
}
.loading-box {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
}
.btn-cx {
  margin: 10px auto 20px auto;
  width: 302px;
  height: 45px;
  background: #dc5151;
  border-radius: 6px;
  text-align: center;
  line-height: 45px;
  color: #ffffff;
}
.btn-fy {
  margin: 10px auto 0px auto;
  width: 302px;
  height: 45px;
  background: #0073ee;
  border-radius: 6px;
  text-align: center;
  line-height: 45px;
  color: #ffffff;
}
.btn-yfy {
  margin: 10px auto 0px auto;
  width: 302px;
  height: 45px;
  background: #e0ecf8;
  border-radius: 6px;
  text-align: center;
  line-height: 45px;
  color: #0073ee;
  border: 1px solid #aad3ff;
  box-sizing: border-box;
}
</style>
